import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { matchDataToDateKeys } from "./utils/dates";
import "./Graph.css";

export interface Props {
  keys: string[];
  dates: string[];
  title: string;
}

const Graph: React.FC<Props> = (props: Props) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const keys: string[] = props.keys;
    const dates: string[] = props.dates;
    fetch(
      `https://forward.tabplayer.online:2345/readCounters?keys=${keys.join(
        ","
      )}&dates=${dates.join(",")}`
    )
      .then(response => response.json())
      .then(response => {
        setData(matchDataToDateKeys(response.data, dates));
      });
  }, [props.keys, props.dates]);

  return (
    <div className="graph">
      <Chart
        options={{
          dataLabels: {
            style: {
              colors: ["#FFF"]
            }
          },
          chart: {
            id: "graph-" + Math.random(),
            toolbar: {
              show: false
            }
          },
          xaxis: {
            categories: data.map(d => d.date)
          }
        }}
        series={[
          {
            name: props.title,
            data: data.map(d => d.value)
          }
        ]}
        type="line"
        width="100%"
      />
    </div>
  );
};

export default Graph;
