import React from "react";
import Graph from "./Graph";
import { dateMinusDays, getDateKeys } from "./utils/dates";
import "./App.css";

const App: React.FC = () => {
  const now = new Date(new Date().getTime());

  const appstart7Day = {
    keys: ["appstart"],
    dates: getDateKeys(dateMinusDays(365), now, "day")
  };
  const appstartMonthly = {
    keys: ["appstart"],
    dates: getDateKeys(dateMinusDays(365), now, "month")
  };

  const openTab7Day = {
    keys: ["open_tab"],
    dates: getDateKeys(dateMinusDays(365), now, "day")
  };
  const openTabMonthly = {
    keys: ["open_tab"],
    dates: getDateKeys(dateMinusDays(365), now, "month")
  };

  const openChord7Day = {
    keys: ["open_chord"],
    dates: getDateKeys(dateMinusDays(365), now, "day")
  };
  const openChordMonthly = {
    keys: ["open_chord"],
    dates: getDateKeys(dateMinusDays(365), now, "month")
  };

  return (
    <div>
      <Graph
        title="Appstart Yearly"
        dates={appstart7Day.dates}
        keys={appstart7Day.keys}
      />
      <Graph
        title="Appstart 12 months"
        dates={appstartMonthly.dates}
        keys={appstartMonthly.keys}
      />

      <Graph
        title="Open Tab Yearly"
        dates={openTab7Day.dates}
        keys={openTab7Day.keys}
      />
      <Graph
        title="Open Tab 12 months"
        dates={openTabMonthly.dates}
        keys={openTabMonthly.keys}
      />

      <Graph
        title="Open Chord 7 days"
        dates={openChord7Day.dates}
        keys={openChord7Day.keys}
      />
      <Graph
        title="Open Chord 12 months"
        dates={openChordMonthly.dates}
        keys={openChordMonthly.keys}
      />
    </div>
  );
};

export default App;
