import moment from "moment";

export type TimeInterval = "hour" | "day" | "week" | "month" | "year";

const intervalFormats = {
  hour: "YY-MM-DD-HH",
  day: "YY-MM-DD",
  week: "YY-Wo",
  month: "YY-MM",
  year: "YY"
};

const intervalValues = {
  hour: 1000 * 3600,
  day: 1000 * 3600 * 24,
  week: 1000 * 3600 * 24 * 7,
  month: 1000 * 3600 * 24 * 30,
  year: 1000 * 3600 * 24 * 365
};

export const dateMinusDays = (count: number) => {
  const d = new Date();
  d.setDate(d.getDate() - count);
  return d;
};

export const getDateKeys = (start: Date, end: Date, interval: TimeInterval) => {
  let startTmp = start.getTime();
  const endTmp = end.getTime();
  const int = intervalValues[interval];
  const keys: string[] = [];

  while (startTmp < endTmp) {
    keys.push(moment(startTmp).format(intervalFormats[interval]));
    startTmp += int;
  }

  return keys;
};

export const matchDataToDateKeys = (data: any[], keys: string[]) => {
  const result: any[] = [];
  let miss = 0;
  for (let i = 0; i < keys.length; i++) {
    const cur = data[i - miss] || {};
    if (cur["date"] === keys[i]) {
      result.push(cur);
    } else {
      result.push({ date: keys[i], value: 0 });
      miss++;
    }
  }
  return result;
};
